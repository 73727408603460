export const katagoriEnum = Object.freeze({
    baslangic: 1,
    degisim: 2,
    adaptasyon: 4,
  });

export const programlar = {
    bedenselBeslenme: {
        title: "Bedensel Beslenme",
        id: 716204686,
        katagori: katagoriEnum.baslangic,
        analiz: <>"Günde 3 öğün ya da 6 öğün beslenilmeli" gibi bilgiler doğru olduğuna inanılan yanlış bilgilerdir. Bu programda bedenimizi dinlemeyi öğreneceğiz ve bu sayede <span className='font-semibold '>gereksiz beslenmeyi ortadan kaldırıyoruz.</span></>,
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/bedensel-beslenme-poster-revize1-1-2.webp"
    },
    yoga: {
        title: "Yoga",
        id: 716295694,
        katagori: katagoriEnum.adaptasyon,
        analiz: "Yoga; bedenle zihnin disiplinini anlamak ve aralarındaki iletişimi maksimuma çıkarmak için en değerli uygulamadır. İstediğimiz zaman dilediğimiz yerde bu programla yogayı hayatımıza almak artık mümkün.",
        img: "https://www.egecetv.com/wp-content/uploads/2022/05/psi-yoga-poster.webp"
    },
    pilates: {
        title: "Pilates",
        id: 724393288,
        katagori: katagoriEnum.degisim,
        analiz: "Fit bir görünüme sahip olmak için etkili bir yöntem olan pilatesi evinizin huzurunda, dilediğiniz saatte, bir sürü para harcamadan, alanının en uzman eğitmenlerinden öğrenin.",
        img: "https://www.egecetv.com/wp-content/uploads/2022/01/pilates-kapak-1000x1500_result.webp"
    },
    haraketYasamaktır: {
        title: "Hareket Yaşamaktır",
        id: 722918789,
        katagori: katagoriEnum.adaptasyon,
        analiz: <>Kalıcı zayıflamak için odak ne yediğimizde değil ne kadar hareket ettiğimizde olmalıdır. Bu programda hareket inancınızı güçlendirip <span className='font-semibold '>kalıcı zayıflamanın püf noktasını öğreneceksiniz.</span></>,
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/hareket-yasamaktir-poster.webp"
    },
    engelSensin: {
        title: "Engel Sensin",
        id: 716367171,
        katagori: katagoriEnum.baslangic,
        analiz: "Kişinin engel ve hastalıklarına rağmen aslında hareket olanağının olduğunu öğreten, size farklı çözümlerle gelen bu program tüm bahaneleri ortadan kaldırarak sizi ideal bedeninize kavuşturacak.",
        img: "https://www.egecetv.com/wp-content/uploads/2021/12/engel-sensin-in-poster-1_result.webp"
    },
    hareketisyen: {
        title: "Hareketisyen",
        id: 716678252,
        katagori: katagoriEnum.baslangic,
        analiz: "Özümüzde hareket zaten vardır ve doğuştan beri bizimledir. İşteki, evdeki günlük hareketlerimizi, kolay kilo vermek için en verimli hale nasıl getireceğinizi bu programla öğreniyoruz.",
        img: "https://www.egecetv.com/wp-content/uploads/2022/03/dilara-hareketisyen-poster_result.webp"
    },
    düsünceMolası: {
        title: "Düşünce Molası",
        id: 728050946,
        katagori: katagoriEnum.degisim + katagoriEnum.adaptasyon,
        analiz: "Bu program bilinçaltınızda bulunan düşünce ve inançları ona zaman ayırarak; düzenlemeyi, tasarlamayı, etkisizleştirmeyi, güçlendirmeyi ve değiştirmeyi öğretir. Artık düşüncelerinizi yönetebileceksiniz.",
        img: "https://www.egecetv.com/wp-content/uploads/2021/12/dusunce-molasi-poster_result.webp"
    },
    degisimSureci: {
        title: "Değişim Süreci",
        id: 717525545,
        katagori: katagoriEnum.degisim,
        analiz: "Zamanı ve çevremdeki engelleyici etkenleri nasıl yönetebilirim? Nelere odaklanmalıyım? Motivasyonumu nasıl arttırabilirim? Gibi sorularınıza bu programda ilerleyerek yanıt bulacaksınız. ",
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/rev-rev-degisim-sureci-poster.webp"
    },
    ebebeyinCocukDegisim: {
        title: "Ebeveyn Çocuk ve Değişim",
        id: 715931221,
        katagori: katagoriEnum.baslangic,
        analiz: "Bu program pedagog ve psikologlarla; çocuğunuzu doğru anlamanız, tanımanız ve iletişiminizi arttırmanız için hazırlandı. İdeal bedende yaşamaları için çocuklara nasıl yaklaşmalıyız? Cevaplar bu programda.  ",
        img: "https://www.egecetv.com/wp-content/uploads/2022/03/ebeveyn-ve-cocuk_result.webp"
    },
    piskologum: {
        title: "Psikoloğum",
        id: 724390868,
        katagori: katagoriEnum.baslangic + katagoriEnum.degisim + katagoriEnum.adaptasyon,
        analiz: "Kaygı, suçluluk, kötümserlik, erteleme, düşük özgüven ve depresyon problemlerinizden ilaçsız kurtulabilirsiniz. Bu program, duygularınızı harekete geçirecek bilimsel olarak test edilmiş bilgiler sunuyor. ",
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/psikolog-1000x1500-1.webp"
    },
    zihinselBeslenme: {
        title: "Zihinsel Beslenme",
        id: 716170736,
        katagori: katagoriEnum.baslangic,
        analiz: "Bu program, sadece gıdalarla değil; bilgi, duygu ve hareketle beslenmeyi tüm örnekleriyle öğretir. Bedeniniz dinlemeyi öğretir ve bu sayede kendi beslenme uzmanınız olmayı amaçlar.",
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/zihinsel-beslenme-poster-son.webp"
    },
    zihinimizdekiCinsellik: {
        title: "Zihnimizdeki Cinsellik",
        id: 715971794,
        katagori: katagoriEnum.degisim,
        analiz: "Cinselliğimiz biz değiştikçe değişir. Bu programda bedenimizi anlamayı, cinsellikle ilgili sorunlarınızı keşfedip, bu sorunların hangi durumlarda hastalık derecesine ulaştığını öğreneceksiniz.",
        img: "https://www.egecetv.com/wp-content/uploads/2022/04/2sonson1920x1080-cinsel-kapak-2.webp"
    },

    oryantal: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/03/oryantal-kapakk_result.webp'
    },
    hipertrofi: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/05/revize-adil-kapak-1000x1500-1.webp'
    },
    psikosomatik: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/04/psikosomatik-hastaliklar-poster.png-1.webp'
    },
    doktorum: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/02/doktorum-kapak-web_result.webp'
    },
    neuroDance: {
        img: '	https://www.egecetv.com/wp-content/uploads/2022/04/rev-poster-neuro-dans.webp'
    },
    haberler: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/04/reviz-e-haberler-poster.webp'
    },
    belgesel: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/04/belgesel-poster.webp'
    },
    düsünceMolasıUygulaması: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/01/dusunce-molasi-uygulama-revize-son-poster_result.webp'
    },
    estetikZamanı: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/07/estetik-zamani-poster.webp'
    },
    kitapMolası: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/04/kitap-molasi-izico-poster.webp'
    },
    neuroExercse: {
        img: 'https://www.egecetv.com/wp-content/uploads/2021/12/exercise-poster_result.webp'
    },
    avukatım: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/07/avukattt.webp'
    },
    bedenimDegerli: {
        img: '	https://www.egecetv.com/wp-content/uploads/2022/08/bedenim-degerli-poster.webp'
    },
    zihinVeBeden: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/07/zihin-ve-beden-dikey-kapak.jpg'
    },
    sosyalPsikoloji: {
        img: '	https://www.egecetv.com/wp-content/uploads/2022/08/sosyal-psikoloji.webp'
    },
    gurme: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/08/gurme-poster.webp'
    },
    bale: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/08/bale-poster.webp'
    },
    imaj: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/08/imaj-poster.webp'
    },
    bachata: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/08/bachata-poster.webp'
    },
    meditatifDans: {
        img: 'https://www.egecetv.com/wp-content/uploads/2022/04/meditatif-poster-isim-revize.webp'
    }
    // düsünceMolasıUygulaması: {
    //     title: "DÜŞÜNCE MOLASI UYGULAMASI",
    //     img: "https://www.egecetv.com/wp-content/uploads/2022/01/dusunce-molasi-uygulama-revize-son-poster_result.webp"
    // },
    // hipertrofi: {
    //     title: "HİPERTROFİ İNCELMEK GÜÇLENMEK",
    //     img: "https://www.egecetv.com/wp-content/uploads/2022/05/revize-adil-kapak-1000x1500-1.webp"
    // },
}

// Bir program için dik posteri, ismi, fragman linki, açıklaması
export default [
    { title: "Start Page", 
        notAQuestions: true
    },
    { title: "Bu analizi kimin için gerçekleştiriyoruz?", 
        answer:[
            "Kendim", 
            "Çocuğum", // Ebeveyn, Çocuk ve Değişim
            "Eşim",
            "Aile Büyüğüm"
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "1":
                    result.prog = [programlar.ebebeyinCocukDegisim];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Cinsiyetiniz",
        answer:[
            "erkek",
            "kadın"
        ]
    },
    { title: "Yaşınız", 
        answer:[//              G-B                     N-D         K-E
            "Genç: 0-6    Yaslı: 40-   Normal: 25-",
            "Genç: 7-18   Yaslı: 41-50 Normal: 26-35",
            "Genç: 19-25  Yaslı: 51-65 Normal: 36-45",
            "Genç: 26-31  Yaslı: 66-85 Normal: 46-60",
            "Genç: 32+    Yaslı: 86+   Normal: 61+",
        ]
    },
    { title: "Günde kaç öğün besleniyorsunuz?", 
        answer:[
            "4+",// bedensel belsenme
            // "Günde 3 öğün ya da 6 öğün beslenilmeli" gibi bilgiler doğru olduğuna inanılan yanlış bilgilerdir. Bu programda bedenimizi dinlemeyi öğreneceğiz ve bu sayede gereksiz beslenmeyi ortadan kaldırıyoruz. 
            "3", // bedensel belsenme
            // "Günde 3 öğün ya da 6 öğün beslenilmeli" gibi bilgiler doğru olduğuna inanılan yanlış bilgilerdir. Bu programda bedenimizi dinlemeyi öğreneceğiz ve bu sayede gereksiz beslenmeyi ortadan kaldırıyoruz. 
            "2", 
            "1"  
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                case "1":
                    result.prog = [programlar.bedenselBeslenme];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Hareketin hayatınızdaki yeri nedir?",
        answer:[
            "Çok hareketsizim",
            "Aktif spor yapıyorum",
            "Yaşamım Hareketli", 
            "Normal" // Hareket Yaşamaktır
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                    result.prog = [programlar.hareketisyen, programlar.haraketYasamaktır];
                    break;   
                case "3":
                    result.prog = [programlar.haraketYasamaktır];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Yoga, pilates, zumba gibi egzersizlere ayıracak vaktiniz var mı?",
        answer:[
            "Maddi Olarak zoruluyor", // yoga, pilates
            "Spora ilgim yok", // Hareket Yaşamaktır
            "Zamanım var", 
            "Zamanım yok", // yoga, pilates
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "3":
                case "0":
                    result.prog = [programlar.yoga, programlar.pilates];
                    break;
                case "1":
                    result.prog = [programlar.haraketYasamaktır];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Zayıflamanıza engel olacak herhangi bir fiziksel engeliniz var mı?",
        answer:[
            "Var", // Engel Sensin
            "Yok"
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                    result.prog = [programlar.engelSensin];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Hedefinizdeki bedeni zihninizde canlandırabiliyor ya da görebiliyor musunuz?", 
        answer:[
            "Evet", 
            "Hayır" //Düşünce Molası
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "1":
                    result.prog = [programlar.düsünceMolası];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Zayıflama sürecinde motivasyon, irade ve odaklanma problemi yaşadığınız oluyor mu?", 
        answer:[
            "Evet", //Değişim Süreci
            "Hayır"
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                    result.prog = [programlar.degisimSureci];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Uzmanlığı kilo olan bir psikologtan destek aldınız mı?", 
        answer:[
            "Almadım", //Psikoloğum
            "Aldım", 
            "Aldım ama uzmanlığı kilo değildi", //Psikoloğum
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                case "2":
                    result.prog = [programlar.piskologum];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Yıkıcı bir olay sonrasında bir şeyler yeme ihtiyacı duyuyor musunuz?", 
        answer:[
            "Bazen", //Zihinsel Beslenme
            "Her zaman", //Zihinsel Beslenme
            "Yaşadığım olaya göre değişiyor", //Zihinsel Beslenme
            "Böyle bir durum yaşamıyorum"
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                case "1":
                case "2":
                    result.prog = [programlar.zihinselBeslenme];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Kilonuz cinsel hayatınızı etkiliyor mu?", 
        answer:[
            "Evet", //Zihnimizdeki Cinsellik
            "Hayır"
        ],
        effect: (a, answers)=>{
            const result = {};

            switch(a){
                case "0":
                    result.prog = [programlar.zihinimizdekiCinsellik];
                    break;    
            }
            // result.prog = Object.values(programlar)
            
            return result;
        }
    },
    { title: "Teşekkürler, Analiziniz Tamamlanıyor.", 
        notAQuestions: true
    }
]