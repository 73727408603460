import './App.css';
import { Pie } from 'react-chartjs-2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from "react-router-dom";
import test from './test';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
// import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from `chart.js`
// Chart.register(LineController, LineElement, PointElement, LinearScale, Title);

var hsvtorgb = require('hsv-rgb')


const colors = [
	'rgba(255, 99, 132, 1)',
	'rgba(54, 162, 235, 1)',
	'rgba(255, 206, 86, 1)',
	'rgba(75, 192, 192, 1)',
	'rgba(153, 102, 255, 1)',
	'rgba(255, 159, 64, 1)',
	'rgba(255, 99, 132, 1)',
	'rgba(54, 162, 235, 1)',
	'rgba(255, 206, 86, 1)',
	'rgba(75, 192, 192, 1)',
	'rgba(153, 102, 255, 1)',
	'rgba(255, 159, 64, 1)'
];
function MyChart({ r, testUsers }) {
	const [users, setUsers] = useState([]);
	const [soruResult, setSoruResult] = useState([]);
	const [soru, setSoru] = useState(0);

	useEffect(() => {
		if (r[0] == "Toplam") {
			setUsers(testUsers);
		}
		else {
			setUsers(testUsers.filter(u => (u.referanceName == r[0])));
		}
		// var count = res.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
		// var countA = Object.keys(count).reduce((a, cur) =>{
		// 	a.push([cur, count[cur] ]);
		// 	return a;
		// }, []);
		// console.log(countA)
	}, [])
	useEffect(() => {
		if (users.length == 0) return;
		const result = users.map((u) => (u.result ? u.result[soru] : "NULL")).reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
		setSoruResult(result)

	}, [users, soru])
	return (
		<>
			<Tabs>
				<TabList>
					{
						Array(12).fill(0).map((u, i) => (
							<Tab key={i} onClick={() => { setSoru(i) }}>{`Soru ${i}`}</Tab>
						))
					}
				</TabList>
				{
					Array(12).fill(0).map((a, i) => (
						<TabPanel key={i}>
							<h1 className='md:mx-[10%]  text-xl text-center'>{ String(test[soru+1].title) }</h1>
							<div className='md:w-2/6 mx-auto mb-5'>
								<Pie data={{
									labels: [...Object.keys(soruResult).map(a=>{
										console.log(typeof(a))
										if(a == "NULL") return "Teste Baslamayanlar"
										if(a == "-") return "Çözmedi"
										if(!isNaN(a)){
											return String(test[soru+1].answer[a])
										}
										else{
											return a
										}
									})],
									datasets: [
										{
											label: '# of Votes',
											data: [...Object.values(soruResult)],
											backgroundColor: colors,
											borderColor: colors,
											borderWidth: 1
										},
									],
								}} />
							</div>
						</TabPanel>
					))
				}
			</Tabs>
		</>
	)
}
function App() {
	const [testUsers, setTestUsers] = useState([]);
	const [referanceName, setReferanceName] = useState([]);

	const search = useLocation().search;
	const pass = new URLSearchParams(search).get("pass");

	useEffect(() => {
		fetch("https://smart.egecetv.com/api.php?token=" + pass)
			.then(response => response.json())
			.then(d => {
				setTestUsers(d);
				// var count = res;
				setReferanceName([["Toplam", d.length], ...Object.entries(d.map(d => d.referanceName).reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {}))]);

				console.log(d);

				// var count = res.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
				// var countA = Object.keys(count).reduce((a, cur) =>{
				//     a.push([cur, count[cur] ]);
				//     return a;
				// }, []);
			})
			.catch(err => console.error(err));
	}, []);

	const data = {
		labels: ['Ads', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
		datasets: [
			{
				label: '# of Votes',
				data: [12, 19, 3, 5, 2, 3],
				backgroundColor: colors,
				borderColor: colors,
				borderWidth: 1
			},
		],
	};

	return (
		<div className='mx-3 md:mx-auto w-full md:w-5/6'>
			<Tabs>
				<TabList>
					{
						referanceName.map((r, i) => (
							<Tab key={i}>{`${r[0]}: ${r[1]}`}</Tab>
						))
					}
				</TabList>
				{
					referanceName.map((r, i) => (
						<TabPanel key={r[0]}>
							<MyChart r={r} key={i} testUsers={testUsers} />
						</TabPanel>
					))
				}
			</Tabs>
		</div>
	);
}

export default App;
